import React from "react";
import { useMedia } from "use-media";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

const TopImage = require("../../assets/img/feature/marketplace_template/marketplace_temp_header.png");
const TopImage_mob = require("../../assets/img/feature/marketplace_template/marketplace_temp_header.png");

export const icon1 = require("../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../assets/img/feature/sec_2_bg.png");
export const icon3 = require("../../assets/img/feature/sec_3_bg.png");
export const icon4 = require("../../assets/img/feature/sec_4_bg.png");

export const cardOne = require("../../assets/images/homepage/group1.png");
export const cardTwo = require("../../assets/images/homepage/group4.png");

const section1 = require("../../assets/img/feature/marketplace_template/marketplace_temp_sec_1.png");
const section2 = require("../../assets/img/feature/marketplace_template/marketplace_temp_sec_2.png");
const section3 = require("../../assets/img/feature/marketplace_template/marketplace_temp_sec_3.png");
const section4 = require("../../assets/img/feature/marketplace_template/marketplace_temp_sec_4.png");

export const autoDesigner = require("../../assets/img/feature/updated/automation.png");
export const chatChannel = require("../../assets/img/feature/updated/chat-channel.png");
export const logs = require("../../assets/img/feature/updated/logs.png");
export const marketplace = require("../../assets/img/feature/updated/marketplace.png");
export const dialog = require("../../assets/img/feature/updated/dialog.png");
export const onprem_icon = require("../../assets/img/feature/updated/on_prem.png");
export const conversationAI = require("../../assets/img/feature/updated/c_ai.png");
export const chabotApproval = require("../../assets/img/feature/agent_handover/all_feature_approvals_icon.png");
export const faqBuilder = require("../../assets/img/feature/agent_handover/all_feature_faq_icon.png");
export const liveAgent = require("../../assets/img/feature/agent_handover/all_feature_agent_handover_icon.png");
const knowledgeAi = require("../../assets/img/feature/knowledge_ai/knowledgeai_icon.svg");

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];

export const otherFeatures = [
  {
    image: dialog,
    alt: "Chatbot Builder​​",
    header: "Chatbot Builder​",
    content:
      "Use no-code visual canvas to create engaging conversations for your chatbot easily in minutes.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: conversationAI,
    alt: "Conversational AI Platform",
    header: "Conversational AI Platform",
    content:
      "Learn more on workativ assistants’ conversational ai platform capabilities.",
    link: "/conversational-ai-platform/conversational-ai",
  },
  {
    image: autoDesigner,
    alt: "Chatbot Automation​",
    header: "Chatbot Automation​",
    content:
      "Add IT or HR workflow automations to chatbot through app integration and pre-built app workflows in few clicks.",
    link: "/conversational-ai-platform/chatbot-automation",
  },
  {
    image: knowledgeAi,
    alt: "Knowledge AI",
    header: "Knowledge AI​",
    new: true,
    content:
      "Knowledge AI leverages the power of LLMs and Generative AI to provide contextually relevant responses to user queries.",
    link: "/conversational-ai-platform/knowledge-ai",
  },
  {
    image: liveAgent,
    alt: "Live Agent Handover",
    header: "Live Agent Handover",
    content:
      "Seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history.",
    link: "/conversational-ai-platform/agent-handover-chatbot",
  },
  {
    image: chatChannel,
    alt: "Omnichannel",
    header: "Omnichannel",
    content:
      "Deploy chatbot on your favorite collaboration apps like Teams, Slack, or as Chat Widget easily in few clicks.",
    link: "/conversational-ai-platform/chatbot-channels",
  },
  {
    image: chabotApproval,
    alt: "Chatbot Approvals",
    header: "Chatbot Approvals​",
    content:
      "Approving chatbot tasks made easy with our approval management. Set approval reminders, expiry, tracking, and more.",
    link: "/conversational-ai-platform/approval-management-chatbot",
  },

  {
    image: faqBuilder,
    alt: "FAQ Bot Builder",
    header: "FAQ Bot Builder",
    content:
      "Simple and easy to understand UI to help you automate your repetitive FAQs easily in minutes.",
    link: "/conversational-ai-platform/faq-chatbot-builder",
  },
  {
    image: logs,
    alt: "Chatbot Analytics & Performance",
    header: "Chatbot Analytics & Performance",
    content:
      "Gather insight to craft better end user experiences and deliver better chatbot support to your employees.",
    link: "/conversational-ai-platform/chatbot-analytics-performance",
  },
];
export default function DialogDesigner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="#1 leading Chatbot Marketplace with Pre-built Bots - Free Signup, Free to Use"
        description="Workativ's chatbot marketplace with pre-built bot templates, FAQ templates, dialog templates, app workflow templates for free."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="Chatbot Marketplace with Pre-built Bots - Free Signup, Free to Use"
        ogDescription="Workativ's chatbot marketplace with pre-built bot templates, FAQ templates, dialog templates, app workflow templates for free."
      />
      <Container additionalClass={"feature-display-none"}>
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <TopFormWithImage
            image={TopImage}
            altImage={"Conversation market template"}
            image_mob={TopImage_mob}
            additionalClassImage={"width-100"}
            additionalClassSection={"features_indvidual_dec"}
          >
            <TopFormWithImage.Header>
              Jump-start your chatbot with easy-to use pre-built bot templates.
              Go live in minutes.
            </TopFormWithImage.Header>
            <RequestForm isFooterForm={false} />
          </TopFormWithImage>

          {/* {isSmall ? null : <OnScrollPopup />} */}

          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page ">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_content_left">
                  <h2 className="font-section-header">
                    Pre-built bot templates
                  </h2>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    Bot templates are pre-built bots with conversations on most
                    common and repetitive workplace topics covering from IT to
                    HR. Bot templates are easy to download and are editable so
                    you can avoid building from scratch.
                  </p>
                </div>
                <div className="col-md-6 feature_page_img_right ">
                  <img
                    loading="lazy"
                    className
                    src={section1}
                    alt="pre built bot templates"
                  />
                </div>
              </div>
            </div>
          </section>

          {/*  */}
          <div className="features_container_left">
            <section className="features_img_left pl-10-tage landing_page trial_page_img ">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 feature_page_img_right ">
                    <picture>
                      <img
                        loading="lazy"
                        src={section2}
                        alt="workflow template"
                      />
                    </picture>
                  </div>
                  <div className="col-md-6 feature_page_content_left">
                    <h2 className="font-section-header">
                      Pre-built app workflow templates
                    </h2>
                    <p className="font-section-normal-text-testimonials line-height-18">
                      Connecting chatbot with your apps and building workflows
                      requires a lot of development work and consume time. But
                      using workativ’s 100s of pre-built app integrations and
                      app workflows from marketplace, you can quickly connect
                      your chatbot with your favorite apps and automate your
                      tasks instantly. No coding.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section className="features_content_left features_img_left mt-0 pl-10-tage  landing_page trial_page_img bg_trial_page ">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_content_left">
                  <div className="coming_soon_wrapper ml-4">
                    <span className="coming_soon">Coming soon</span>
                  </div>
                  <div className="m-b-20"></div>
                  <h2 className="font-section-header">
                    Pre-built FAQ templates
                  </h2>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    FAQs are easy to create, said no one. Workativ helps you
                    beat the trivia with pre-built, ready to use, editable,
                    common FAQs covering anywhere from printer to access issues
                    right of the bat.
                  </p>
                  <div class="card_link_landing"></div>
                </div>
                <div className="col-md-6 feature_page_img_right ">
                  <img
                    loading="lazy"
                    className
                    src={section3}
                    alt="pre faq builder"
                  />
                </div>
              </div>
            </div>
          </section>

          {/*  */}
          <div className="features_container_left">
            <section className="features_img_left pl-10-tage landing_page trial_page_img ">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 feature_page_img_right ">
                    <picture>
                      <img
                        loading="lazy"
                        src={section4}
                        alt="chatbot dialog template"
                      />
                    </picture>
                  </div>
                  <div className="col-md-6 feature_page_content_left">
                    <div className="coming_soon_wrapper ml-4">
                      <span className="coming_soon ">Coming soon</span>
                    </div>

                    <h2 className="font-section-header">
                      Pre-built chatbot dialog templates
                    </h2>
                    <p className="font-section-normal-text-testimonials line-height-18">
                      Dialog templates helps you use pre-built conversations on
                      most common and repetitive workplace topics covering from
                      IT to HR. Chatbot templates are easy to download and are
                      editable so you can avoid building from scratch.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section className="cards_features bg_fff">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                  <div className="build_needs">
                    <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                      <h2 className="font-section-header">
                        Explore More Features
                      </h2>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        Our 3-in-1 support automation platform combines the
                        power of Gen AI chatbot for automating chats, App
                        workflow automation for auto-resolution of issues, and
                        Shared Live Inbox for seamless live chat interactions.
                        And more…!
                      </p>
                    </div>
                  </div>
                  <section className="whitepaper_cards">
                    <div className="container-fluid p-0 c_a">
                      <div className="row">
                        <ul className="cards">
                          {otherFeatures.map((data) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    loading="lazy"
                                    src={data.image}
                                    alt={data.alt}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title font-section-normal-text-medium">
                                    {data.header}
                                    {data.new ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        New
                                      </span>
                                    ) : null}
                                    {data.beta ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        Beta
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className="card__text font-section-small-text pb-0">
                                    {data.content}
                                  </p>
                                  <div class="card_link_landing font-section-small-text">
                                    <a
                                      class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                                      href={data.link}
                                    >
                                      Know more&nbsp; &#8594;
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          {/* <GoToPopup />
          <CookiesPoup /> */}
          <div className="isfooter_form">
            <RequestForm isFooterForm={true} />
          </div>
        </Layout>
      </Container>
    </>
  );
}
